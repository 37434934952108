import Vue from 'vue';

export const EventBus = new Vue();


export const RefreshResCode= {
    REFRESH_AUTH_FAIL: "01",
    REFRESH_TOKEN_EXPIRED: "02",
    REFRESH_USER_LOGOUT: "03",
    REFRESH_INVALID_TOKEN_KEY: "04",
    REFRESH_USER_INFO_NOT_MATCH: "05",
    REFRESH_INVALID_TOKEN: "06",
    REFRESH_INVALID_TOKEN_SESSION_MATCH: "07",
    REFRESH_STILL_TOKEN_REFRESHING: "08",
    REFRESH_RESULT_OK: "00"
}

export function isEmpty(str){
    if(typeof str == 'undefined' ||  str == 'undefined' ||  str == null ||  str == undefined ||  str == ""){
        return true
    }
    else{
        return false
    }
}

export const UrlUtils={
    getUrlParams(){
        let params = {}
        window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str, key, value) { params[key] = value; });
        return params
    },
    async redirectWithSessionTempKey(url){
        console.log('redirectWithSessionTempKey 호출')
        let rurl = decodeURIComponent (url)
        console.log(rurl)
        window.location.href=rurl
    },
}

/**
 * byte 용량을 환산하여 반환
 * 용량의 크기에 따라 MB, KB, byte 단위로 환산함
 * @param fileSize  byte 값
 * @param fixed     환산된 용량의 소수점 자릿수
 * @returns {String}
 */
export function byte(fileSize, fixed) {
    var str

    //MB 단위 이상일때 MB 단위로 환산
    if (fileSize >= 1024 * 1024) {
        fileSize = fileSize / (1024 * 1024);
        fileSize = (fixed === undefined) ? fileSize : fileSize.toFixed(fixed);
        str = Math.ceil(fileSize) + ' MB';
    }
    //KB 단위 이상일때 KB 단위로 환산
    else if (fileSize >= 1024) {
        fileSize = fileSize / 1024;
        fileSize = (fixed === undefined) ? fileSize : fileSize.toFixed(fixed);
        str = Math.ceil(fileSize)  + ' KB';
    }
    //KB 단위보다 작을때 byte 단위로 환산
    else {
        fileSize = (fixed === undefined) ? fileSize : fileSize.toFixed(fixed);
        str = Math.ceil(fileSize)  + ' byte';
    }
    return str;
}
