import {isEmpty} from "@/lib/CommUtil";

export const SessionStorageProxy = {
    setValue(key, value){
        sessionStorage.setItem(key,value)
    },
    setJsonValue(key, value){
        sessionStorage.setItem(key, JSON.stringify(value))
    },
    getValue(key) {
        return sessionStorage.getItem(key)
    },

    getJsonValue(key,defaultValue){
        const value = sessionStorage.getItem(key)
        if( isEmpty(value)){
            if(isEmpty(defaultValue)){
                return null
            }
            return defaultValue;
        }
        return JSON.parse(value)
    },
    containsOf(key){
        const value = sessionStorage.getItem(key)
        if( isEmpty(value)){
            return false;
        }
        return true
    },
    remove(key){
        sessionStorage.removeItem(key)
    }
}


const LocalStorageProxy = {
    setToken(token, refresh) {
        localStorage.setItem('token', token)
        localStorage.setItem('refreshtoken', refresh)
    },
    getToken() {
        return localStorage.getItem('token')
    },
    getRefreshToken() {
        return localStorage.getItem('refreshtoken')
    },
}


export const SessionToken = {
        setSession(sessionObj){
            LocalStorageProxy.setToken(sessionObj.token, sessionObj.refresh)
        },
        isLogin(){
            const token = this.getToken()
            if( token === '' || token === null){
                return false;
            }
            return true;
        },
        getToken() {
            return LocalStorageProxy.getToken();
        },
        getRefreshToken() {
            return LocalStorageProxy.getRefreshToken();
        },
        clear(){
            localStorage.clear()
            sessionStorage.clear()
        },
}



