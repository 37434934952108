const {isEmpty} = require("@/lib/CommUtil");
import axios from 'axios'
import {SessionToken} from "@/lib/SessionManager";

const API_DOMAIN =  process.env.VUE_APP_API_HOST
const responseTimeout = 10000

const apiAuthDomain = axios.create({
    baseURL: API_DOMAIN,
    headers: {
        post: {        // can be common or any other method
            "Content-Type": "application/json;charset=UTF-8"
        },
        put:{
            "Content-Type": "application/json;charset=UTF-8"
        },
        'Accept': 'application/json;charset=UTF-8'
    },
    timeout: responseTimeout
})

export const tokenApi = apiAuthDomain


apiAuthDomain.interceptors.request.use(commonRequestFilter, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

apiAuthDomain.interceptors.response.use((response) => {
    return response;
}, exceptionHandler);




export function commonRequestFilter(config){
    let token = SessionToken.getToken()
    if(isEmpty(token) === false){
        config.headers['Authorization'] = `Bearer ${token}`
        return config
    }else{
        console.error('토큰이 없습니다')
        return config
    }
}


export async function exceptionHandler(error) {
    console.log('인터셉터 - 에러일 경우');
    const errorAPI = error.config;
    const errorRes = error.response;


    if(errorRes.status===401 && errorAPI.retry===undefined && SessionToken.isLogin()){
        errorAPI.retry = true;
        console.log('토큰이 이상한 오류일 경우');
        let flg = true
        let needTologout = false
        // 리프레시 토큰으로 토큰 발급 요청
        await AuthApi.refresh().then(data => {
                console.log('요청 성공  , ', data)
                // 토큰 발급 실패
                if (data.code != RefreshResCode.REFRESH_RESULT_OK) {
                    console.log('토큰 발급 실패')
                    //sessionTokenApi.clear();
                    //this.$store.dispatch('LOGOUT')
                    needTologout = true;
                }
            }
            // 토큰 발급 실패
        ).catch( error => {
            //this.$store.commit('LOGOUT')
            //sessionTokenApi.clear();
            this.$store.dispatch('LOGOUT')
            console.log('토큰 발급 에러 예외처리')
            console.log(error)
            flg=false;
            needTologout = true;
        })

        if(needTologout){
            console.log('토큰 발급 실패2')
            //await this.$store.dispatch('LOGOUT')
            //console.log('토큰 발급 실패3')
            EventBus.$emit('logout', '1');
            return Promise.reject(new Error('에러다!!!!!11111'));
        }

        // 토큰 발급이 성공했을 때 재요청을 한다
        if( flg === true){
            console.log('재호출 ---')
            //errorAPI.defaults.headers.component['Authorization'] = 'Bearer ' + sessionTokenApi.getToken();
            errorAPI.headers['Authorization'] = 'Bearer ' + SessionToken.getToken();

            return new Promise((resolve, reject) => {
                axios.request(errorAPI).then(response => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                })
            });
        }

    }
    // 인증 문제가 아닌 에러인 경우
    console.log('err 리턴');
    if(errorRes.status===401 && !SessionToken.isLogin()){
        console.log('로그인 페이지로 이동')
        //await this.$router.push({name:'login'})
        EventBus.$emit('logout', '1');
    }
    return Promise.reject(error);
}
